import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            cDay: "",
            day: "",
            time: "",

            name: "",
            tel: "",
            email: "",
            contact: "",
            company: "",
            founding: "",
            ceo: "",
            fax: "",
            web: "",
            capital: "",
            job: "",
            address2: "",

            unitPrice: "",
            kei: "",
            normal: "",
            onebox: "",

            station: null,
            stations: [],

            carNum: 0,
            point: 0,
            allSquares: 0,

            steps: [0],
            squares: [0],
            prices: [0],
            amounts: [0],
            zyous: [0],
            cars: [null],

            payment: "",
            discount: 0,
            target: "",
            campaign: 0,

            requredRules: [(v) => !!v || "入力必須です"],
            bankNumRules: [(v) => v.length == 7 || "半角数字７桁です"],
            moneyRules: [(v) => !!v || "入力必須です", (v) => v >= 0 || "0以上です"],

            payments: [
                {
                    check: false,
                    name: "施工当日支払い",
                },
                {
                    check: true,
                    name: "銀行振込",
                },
                {
                    check: false,
                    name: "クレジットカード決済",
                },
            ],
            baikas: [
                11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000,
                19500, 21000, 22000, 23000, 24000, 25000, 26000, 27000, 28000,
                29000, 30000, 31000, 32000, 32000, 33000, 34000, 35000, 35000,
                36000, 37000, 37000, 38000, 39000, 39000, 40000, 40000, 41000,
                41000, 42000, 42000, 43000, 44000, 45000, 45000, 45000, 46000,
                46000, 47000, 47000, 47000, 48000, 50000, 50000, 51000, 51000,
                52000, 52000, 52000, 53000, 53000, 54000, 56000, 57000, 57000,
                58000, 58000, 59000, 59000, 60000, 60000, 60000, 61000, 61000,
                62000, 62000, 63000, 63000, 63000, 64000, 64000, 65000, 68000,
                68000, 69000, 69000, 70000, 70000, 71000, 71000, 72000, 72000,
                72000, 73000, 73000, 74000, 74000, 75000, 75000, 76000, 76000,
                77000,
            ],
            tatamis: [
                {
                    zyou: "4.5畳", square: 7
                },
                {
                    zyou: "6畳", square: 10
                },
                {
                    zyou: "8畳", square: 13
                },
                {
                    zyou: "10畳", square: 15
                },
                {
                    zyou: "12畳", square: 19
                },
            ],
            subject: "",
            subjects: [
                "正確な見積をしてほしい", "詳しい説明を聞きたい", "その他"
            ],
            etc: "",
        };
    },

    validations: {
        email: {
            required,
            email,
        },
    },
    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push("メールアドレスの形式が違います");
            !this.$v.email.required && errors.push("入力必須です");
            return errors;
        },
        Info() {
            return this.$store.getters["user/info"];
        },
        Station() {
            return this.$store.getters["user/station"];
        },
        types() {
            return [
                {
                    id: 0,
                    name: "軽自動車",
                    price: this.Info.kei,
                },
                {
                    id: 1,
                    name: "普通車(セダン･クーペ等)",
                    price: this.Info.normal,
                },
                {
                    id: 2,
                    name: "ワンボックス(ハイエース･キャラバン等)",
                    price: this.Info.onebox,
                },
            ];
        },
    },
};
